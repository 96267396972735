import { action, observable } from 'mobx'

import AppConsts from './../lib/appconst'
import LoginModel from '../models/account/Login/loginModel'
import tokenAuthService from '../services/tokenAuth/tokenAuthService'
import { notifyError } from '@lib/helper'
import { L } from '@lib/abpUtility'

declare let abp: any

class AuthenticationStore {
  @observable isLoading!: boolean
  @observable phoneLoginModel!: any
  @observable loginModel: LoginModel = new LoginModel()
  @observable loginResult: any

  // @observable firebaseBody: any = {}
  get isAuthenticated(): boolean {
    if (!abp.session.userId) return false

    return true
  }

  @action
  public async checkPhoneNumber(phoneNumber) {
    const result = await tokenAuthService.checkPhoneNumber(phoneNumber)
    return result
  }

  @action
  public async loginWithCustomer(model: LoginModel) {
    this.isLoading = true
    this.loginResult = await tokenAuthService
      .authenticateWithCustomer({
        emailAddress: model.emailAddress,
        password: model.password,
        rememberClient: model.rememberMe,
        projectId: model.projectId,
        agentId: model.agentId
      })
      .finally(() => (this.isLoading = false))

    if (this.loginResult.accessToken) {
      const tokenExpireDate = model.rememberMe
        ? new Date(
          new Date().getTime() + 1000 * this.loginResult.expireInSeconds
        )
        : undefined
      abp.auth.setToken(this.loginResult.accessToken, tokenExpireDate)
      abp.utils.setCookieValue(
        AppConsts.authorization.encrptedAuthTokenName,
        this.loginResult.encryptedAccessToken,
        tokenExpireDate,
        abp.appPath,
        undefined,
        { Secure: true }
      )
    }
  }

  @action
  public async login(model: LoginModel) {
    this.isLoading = true
    const result = await tokenAuthService
      .authenticate({
        userNameOrEmailAddress: model.userNameOrEmailAddress,
        password: model.password,
        rememberClient: model.rememberMe
      })
      .finally(() => (this.isLoading = false))

    if (result.statusCode == -1) {
      notifyError(L('Failed'), L('Invalid username or password'))
      return
    }

    const tokenExpireDate = model.rememberMe
      ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
      : undefined
    abp.auth.setToken(result.accessToken, tokenExpireDate)
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
      undefined,
      { Secure: true }
    )
  }

  @action
  public async loginWithAdmin(model: LoginModel) {
    this.isLoading = true
    const result = await tokenAuthService
      .authenticateWithAdmin({
        userNameOrEmailAddress: model.userNameOrEmailAddress,
        password: model.password,
        rememberClient: model.rememberMe
      })
      .finally(() => (this.isLoading = false))

    const tokenExpireDate = model.rememberMe
      ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
      : undefined
    abp.auth.setToken(result.accessToken, tokenExpireDate)
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
      undefined,
      { Secure: true }
    )
  }

  @action
  public async loginSMS(body) {
    this.isLoading = true
    const result = await tokenAuthService
      .SMSAuth(body)
      .finally(() => (this.isLoading = false))

    const tokenExpireDate = new Date(
      new Date().getTime() + 1000 * result.expireInSeconds
    )
    abp.auth.setToken(result.accessToken, tokenExpireDate)
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
      undefined,
      { Secure: true }
    )
  }

  @action
  public async getMethod() {
    const res = await tokenAuthService.getLoginMethod()
    return res
  }

  @action
  public async registerBySMS(body) {
    this.isLoading = true
    const result = await tokenAuthService
      .registerBySMS(body)
      .finally(() => (this.isLoading = false))
    const tokenExpireDate = new Date(
      new Date().getTime() + 1000 * result.expireInSeconds
    )
    abp.auth.setToken(result.accessToken, tokenExpireDate)
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
      undefined,
      { Secure: true }
    )
  }

  @action
  public async registerAccount(body) {
    this.isLoading = true
    return await tokenAuthService
      .registerAccount(body)
      .finally(() => (this.isLoading = false))
  }

  @action
  public async registerAgentAccount(body) {
    this.isLoading = true
    return await tokenAuthService
      .registerAgentAccount(body)
      .finally(() => (this.isLoading = false))
  }

  @action
  public async sendOtpVerifyLogin(body) {
    this.isLoading = true
    return await tokenAuthService
      .sendOtpVerifyLogin(body)
      .finally(() => (this.isLoading = false))
  }

  @action
  public async verifyOtp(model) {
    this.isLoading = true
    this.loginResult = await tokenAuthService
      .verifyOtpLogin({
        emailAddress: model.emailAddress,
        password: model.password,
        confirmationCode: model.confirmationCode
      })
      .finally(() => (this.isLoading = false))

    const { accessToken, message, content } = this.loginResult;
    if (accessToken) {
      const tokenExpireDate = model.rememberMe
        ? new Date(
          new Date().getTime() + 1000 * this.loginResult.expireInSeconds
        )
        : undefined
      abp.auth.setToken(accessToken, tokenExpireDate)
      abp.utils.setCookieValue(
        AppConsts.authorization.encrptedAuthTokenName,
        this.loginResult.encryptedAccessToken,
        tokenExpireDate,
        abp.appPath,
        undefined,
        { Secure: true }
      )
    } else {
      notifyError(message, content)
    }
    return this.loginResult;
  }

  @action
  logout() {
    abp.utils.deleteCookie(
      AppConsts.authorization.encrptedAuthTokenName,
      abp.appPath
    )
    abp.utils.deleteCookie(AppConsts.authorization.projectId, abp.appPath)

    localStorage.clear()
    sessionStorage.clear()
    abp.auth.clearToken()
  }
}

export default AuthenticationStore
