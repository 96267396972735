import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

interface ILoadingProcess {
  startLoading?: boolean,
}

const LoadingScreenCustom = observer((props: ILoadingProcess) => {
  // const [start, setStart] = useState<boolean>(false);
  const [process, setProcess] = useState<number>(0);

  const step = 5;
  const stepEnd = "We are ready for you — Please come in";
  const stepStart = "Sit tight — We’re crafting beautiful views of your next property";
  const stepLoading = "Almost there — Applying the final touches";

  const setAnimationAt = (val: number) => {
    let timeOut: any = null;
    let changingProcess = 0;
    timeOut = setInterval(() => {
      changingProcess += step;
      setProcess(changingProcess)

      if (changingProcess == 100) {
        clearInterval(timeOut)
      }
    }, 100);
  }

  // Thinkable
  // const runAfterApiDone = () => {
  //   Promise.all([
  //     apiCall_1,
  //     apiCall_2,
  //     apiCall_3,
  //   ])
  //     .then(() => { })
  //     .finally(() => { setProcess(100) })
  // }
  const _init = () => {
    setAnimationAt(0);
  }
  useEffect(() => { _init() }, []);

  return (
    <div className="loading-screen">
      {/* <div className={`loading-process `} style={{
          height: (process <= 50 ? process : 50) + '%',
          animationName: process <= 50 ? "anim_progress" : "anim_end_progress"
        }}></div> */}
      <div className={`loading-process `} style={{ animationName: "anim_progress" }}></div>
      {/* <div className={`loading-process `} style={{ height: process + "%" }}></div> */}
      <div className="loading-screen_inner">
        <img className="f-logo" src="/assets/images/single-logo-w.png" />
        <div className="wrap-text">
          {
            process == 100 ? <span>{stepEnd}</span> :
              process >= 50 ? <span> {stepLoading}</span> :
                <span>{stepStart}</span>
          }
        </div>
      </div>
    </div >
  )
})

export default inject()(LoadingScreenCustom);
