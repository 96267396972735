export enum DocumentImageEOIType {
  eoiLicenseFront = 12,
  eoiLicenseBack = 13,
  eoiCompanyExtract = 14,
  eoiTrustDeed = 15,
}

export enum ProgressEOI {
  stepBuyer = 0,
  stepUploadLicense = 1,
  stepSolicitor = 2,
  stepPayment = 3,
  stepSignature = 4
}

export enum EOIVerifyStepEnum {
  verifyBuyer = 1,
  verifyUploadDocument = 2,
  verifySolicitor = 3,
  verifyPayment = 4,
  verifyReview = 5
}

export enum PaymentTypeEnum {
  paymentOnline = 1,
  paymentDirect = 2
}

export const stateAUS = [
  {
    "id": 1,
    "name": "New South Wales",
    "code": "NSW",
    "capital": "Sydney",
    "population": 8166369
  },
  {
    "id": 2,
    "name": "Victoria",
    "code": "VIC",
    "capital": "Melbourne",
    "population": 6691857
  },
  {
    "id": 3,
    "name": "Queensland",
    "code": "QLD",
    "capital": "Brisbane",
    "population": 5184847
  },
  {
    "id": 4,
    "name": "Western Australia",
    "code": "WA",
    "capital": "Perth",
    "population": 2763612
  },
  {
    "id": 5,
    "name": "South Australia",
    "code": "SA",
    "capital": "Adelaide",
    "population": 1770599
  },
  {
    "id": 6,
    "name": "Tasmania",
    "code": "TAS",
    "capital": "Hobart",
    "population": 541100
  },
  {
    "id": 7,
    "name": "Australian Capital Territory",
    "code": "ACT",
    "capital": "Canberra",
    "population": 453324
  },
  {
    "id": 8,
    "name": "Northern Territory",
    "code": "NT",
    "capital": "Darwin",
    "population": 249348
  }
]