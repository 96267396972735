import { Button } from "antd";
import './ValidLoginModal.less';

const ValidLoginModal = (props) => {
  const { onCloseModal, title, content } = props;

  return (
    <div className="modal-valid-login">
      {/* <Modal
        open={visibleValidLogin}
        footer={null}
        closable={false}
        maskClosable={false}
        centered
        className="modal-valid-login"> */}
      <div className="md-header">
        <span><i className="fa-regular fa-triangle-exclamation"></i></span>
        <span>{title}</span>
      </div>
      <div className="md-content">{content}</div>
      <div className="md-footer">
        <Button className="button-action" onClick={onCloseModal}>Ok</Button>
      </div>
      {/* </Modal> */}
    </div>
  )
}

export default ValidLoginModal;